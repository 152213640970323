<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start>
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-center>
          <v-flex xs12 lg10 align-self-center>
            <v-layout wrap justify-center>
              <v-flex xs12 align-self-center pa-2 py-6>
                <v-layout wrap>
                  <v-flex xs12 pa-2 v-for="(item, i) in cardData" :key="i">
                    <v-card elevation="1" tile outlined>
                      <v-layout wrap>
                        <v-flex xs12 sm4>
                          <v-img
                            :contain="
                              $vuetify.breakpoint.name == 'xs' ? false : true
                            "
                            :src="item.image"
                            class="align-center"
                          >
                            <template v-slot:placeholder>
                              <ImageLoader />
                            </template>
                          </v-img>
                        </v-flex>
                        <v-flex xs12 sm8 pa-2>
                          <v-layout wrap fill-height>
                            <v-flex xs12 sm12 pa-6 text-left>
                              <span
                                class="poppinsregular"
                                style="color:#000;font-size:22px"
                              >
                                {{ item.desc }}
                              </span>
                            </v-flex>
                            <!-- <v-flex sm4></v-flex> -->
                            <v-flex xs12 sm6 align-self-end text-left pa-6>
                              <span
                                class="poppinssemibold"
                                style="font-size:25px"
                                >Ready for Challenge?</span
                              >
                            </v-flex>
                            <v-flex xs12 sm6 align-self-end text-right pa-6>
                              <a
                                target="_blank"
                                href="https://forms.gle/VJFXkyrVh5r2Yt3Z6"
                              >
                                <v-btn dark x-large tile color="#005f32">
                                  <span
                                    class="poppinssemibold"
                                    :style="
                                      $vuetify.breakpoint.name == 'sm'
                                        ? 'font-size:20px'
                                        : 'font-size:25px'
                                    "
                                    >Take the Quiz</span
                                  >
                                </v-btn>
                              </a>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex xs12 align-self-center px-4 pt-10 pb-16>
                <span :style="$vuetify.breakpoint.name=='xs'?'color:#000;font-size:18px':'color:#000;font-size:22px'" class="poppinssemibold">
                  For queries, please write to <a target="_blank" href="mailto:po.communication@wti.org.in">
                    <span style="color:blue">po.communication@wti.org.in</span>
                  </a>
                </span>
              </v-flex> -->
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["banner"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      rulesInfo: [],
      ruleselected: null,
      cardData: [
        {
          image: require("./../../assets/images/Fquiz.jpg"),
          desc:
            "Test your ‘Frog Wisdom’ and see how much you know about the frogs.  Three winners will be chosen at random and we will send the ‘surPrizes’!!",
        },
      ],
    };
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
.bannerGrad {
  background: transparent;

  background-image: linear-gradient(
    104deg,
    #bc4639 6%,
    #7e7e7e2a 94%,
    transparent 100%
  );
}
.rot {
  animation-duration: 3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
</style>
