<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start>
      <v-flex xs12 align-self-center style="margin-all: 0px;">
        <v-layout wrap justify-center>
          <v-flex
            xs12
            lg10
            :style="
              $vuetify.breakpoint.name == 'xs'
                ? 'margin-top:0%'
                : 'padding-top:0%'
            "
            align-self-center
          >
            <v-layout wrap justify-center>
              <v-flex xs12 align-self-center pt-10>
                <v-card
                  tile
                  flat
                  outlined
                  class="rounded-xl"
                  style="margin-top:2px "
                >
                  <v-layout wrap justify-center py-6>
                    
                    
                    <v-flex xs12 pa-2 align-self-center py-8>
                      <span
                        class="poppinssemibold"
                        style="color:#000;font-size:34px"
                      >
                    
                        Here’s your chance to win
                        <span
                          style="cursor:pointer;"
                          @click="clickedOn2()"
                          class="hovCls"
                          >EXCITING GIFTS</span
                        >
                        and be a part of WTI’s Amphibian Recovery Project.
                      </span>
                    </v-flex>
                    <v-flex xs12 md10 lg8 align-self-center pa-4 py-8>
                      <v-card tile>
                        <v-layout wrap justify-center pa-4>
                          <v-flex xs12 pt-2 text-center align-self-start>
                            <span
                              style="font-family: poppinsbold; font-size: 24px"
                              >Share Via</span
                            >
                          </v-flex>
                          <v-flex
                            xs2
                            sm2
                            text-left
                            py-4
                            px-2
                            px-sm-4
                            v-for="(item, i) in socialMedias"
                            :key="i"
                          >
                            <ShareNetwork
                              :network="item.network"
                              :url="
                                'https://amphibians.wti.org.in' +
                                  $route.fullPath
                              "
                              :title="
                                item.network == 'messenger' ||
                                item.network == 'linkedin'
                                  ? ''
                                  : ''
                              "
                            >
                              <v-img
                                :width="item.size"
                                contain
                                :src="getImgUrl(item.image)"
                              ></v-img>
                            </ShareNetwork>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <!-- <v-flex xs12 align-self-center pa-4 py-8>
                      <span
                        class="poppinssemibold"
                        style="color:#000;font-size:28px"
                      >
                        While it is a contest…don’t forget to have LOTS of fun
                        and Ribbit!
                      </span>
                    </v-flex> -->
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- <v-flex xs12 align-self-center pa-4 py-10 text-left>
                <span class="poppinssemibold" style="color:#000;font-size:24px">
                  Pick ANY ONE of the three themes (‘Click a Frog’, ‘Paint a
                  Frog’, ‘Be a Frog’) OR you may take the Quiz and test your
                  ‘Frog Wisdom’!
                </span>
              </v-flex> -->
              <!-- <v-flex xs12 align-self-center pa-2 pa-md-4 py-6 text-left>
                <span class="poppinsregular" style="color:#000;font-size:22px">
                  Please use #FrogsForPlanet #WTI as you share the contest with
                  friends and family!
                </span>
                &nbsp;

                <v-btn tile x-large @click="shareDialog = true" color="primary">
                  <span class="poppinssemibold">Share</span>
                </v-btn>
                <v-dialog
                  v-model="shareDialog"
                  :width="
                    $vuetify.breakpoint.name == 'xs'
                      ? '100vw'
                      : $vuetify.breakpoint.name == 'sm'
                      ? '50vw'
                      : $vuetify.breakpoint.name == 'md'
                      ? '40vw'
                      : $vuetify.breakpoint.name == 'lg'
                      ? '40vw'
                      : '20vw'
                  "
                >
                  <v-card>
                    <v-layout wrap justify-center pa-4>
                      <v-flex xs12 pt-2 text-center align-self-start>
                        <span style="font-family: poppinsbold; font-size: 24px"
                          >Share Via</span
                        >
                      </v-flex>
                      <v-flex
                        xs6
                        sm2
                        text-left
                        py-4
                        px-4
                        v-for="(item, i) in socialMedias"
                        :key="i"
                      >
                        <ShareNetwork
                          :network="item.network"
                          :url="
                            'https://amphibians.wti.org.in' + $route.fullPath
                          "
                          :title="
                            item.network == 'messenger' ||
                            item.network == 'linkedin'
                              ? ''
                              : ''
                          "
                        >
                        
                          <v-img
                            :width="item.size"
                            contain
                            :src="getImgUrl(item.image)"
                          ></v-img>
                        </ShareNetwork>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-dialog>
              </v-flex> -->

              <v-flex xs12 align-self-center pa-4 py-10 text-center>
                <!-- <v-btn dark tile color="red"> -->
                <span
                  class="oswaldbold"
                  style="color:#005f32 !important;font-size:39px;"
                >
                  Pick a Theme
                </span>
                <!-- </v-btn> -->
              </v-flex>
              <v-flex xs12 align-self-center pa-2 py-6>
                <v-layout wrap justify-start>
                  <v-flex xs12 pa-2 v-for="(item, i) in cardData" :key="i">
                    <v-card elevation="1" tile outlined @click="clickedOn()">
                      <v-layout wrap>
                        <v-flex xs12 sm4>
                          <v-img
                            :contain="
                              $vuetify.breakpoint.name == 'xs' ? false : true
                            "
                            :src="item.image"
                            class="align-center"
                          >
                            <!-- <template v-slot:placeholder>
                <ImageLoader />
              </template> -->
                            <!-- <v-layout wrap>
                              <v-flex xs12>
                                <span
                                  :style="
                                    $vuetify.breakpoint.name == 'xs' ||
                                    $vuetify.breakpoint.name == 'sm'
                                      ? 'font-size:25px;text-shadow: 2px 2px #000;'
                                      : 'font-size:39px;text-shadow: 2px 2px #000;'
                                  "
                                  style="color:#ffffff"
                                  class="oswaldbold"
                                >
                                  {{ item.title }}
                                </span>
                              </v-flex>
                            </v-layout> -->
                          </v-img>
                        </v-flex>
                        <v-flex xs12 sm8 align-self-center text-left>
                          <v-layout wrap>
                            <!-- <v-flex xs12 align-self-center pt-4 px-4 text-left>
                              <span
                                :style="
                                  $vuetify.breakpoint.name == 'xs' ||
                                  $vuetify.breakpoint.name == 'sm'
                                    ? 'font-size:25px;'
                                    : 'font-size:25px;'
                                "
                                style="color:#000;"
                                class="oswaldbold"
                              >
                                {{ item.title }}
                              </span>
                            </v-flex> -->
                            <v-flex xs12 pa-4 text-justify>
                              <span
                                class="poppinsregular texth"
                                style="color:#000;font-size:20px;"
                              >
                                {{ item.desc }}
                              </span>
                            </v-flex>
                            <v-flex xs12 pa-4 text-justify>
                              <v-layout wrap justify-end>
                                <v-flex
                                  xs12
                                  sm6
                                  md5
                                  lg4
                                  align-self-center
                                  text-center
                                >
                                  <v-card tile color="#005f32" class="pa-2">
                                    <span
                                      class="poppinssemibold text-uppercase"
                                      style="background-color:#005f32;color:#ffffff;font-size:25px;letter-spacing:1px"
                                    >
                                      Submit Entries
                                    </span>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex xs12 pa-2 align-self-center py-8>
                <v-layout wrap justify-center>
                  <v-flex xs12 align-self-center text-justify>
                    <span class="poppinssemibold" style="color:#000">
                  What’s a contest without RULES? So, here they are… 
                </span>
                  </v-flex>
                  <v-flex xs10 sm4 pa-2 pa-md-0 align-self-center text-left>
                       <v-select dense hide-details outlined v-model="ruleselected" :items="rulesInfo"></v-select>
            
                  </v-flex>
                </v-layout>
        
               </v-flex> -->
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <Quiz />
          </v-flex>

          <v-flex xs12 pa-4 align-self-center py-8 id="ruleId">
            <v-layout wrap justify-center>
              <v-flex xs12 align-self-center text-justify py-2 px-6>
                <!-- <span class="poppinssemibold" style="color:#000;font-size:20px">
                  What’s a contest without RULES? So, here they are…
                </span> -->
                <span
                  style="font-size:23px;color:#000;"
                  class=" poppinsbold text-uppercase"
                >
                  <b> CONTEST RULES </b>
                </span>
              </v-flex>
              <v-flex xs12 pa-2 pa-md-0 align-self-center text-left>
                <!-- <v-select dense hide-details outlined v-model="ruleselected" :items="rulesInfo"></v-select> -->

                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span style="font-size:18px" class="poppinssemibold">
                        BASIC SUBMISSIONS GUIDELINES
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="poppinsregular" style="font-size:18px">
                        <ul class="a">
                          <li>
                            The contest is free and open only for Indian
                            nationals.
                          </li>
                          <li>
                            Duration of the contest is from 05 May ‘22 till 27
                            May ‘22. No entries will be accepted after 27 May
                            ‘22, 11:59 PM (IST).
                          </li>
                          <li>
                            The results and winners will be announced on 30 May
                            ’22, on WTI website contest page.
                          </li>
                          <li>
                            Photos should be no larger than 3MB and in JPEG
                            format.
                          </li>
                          <!-- <li>
                            Participants submitting entries for ‘Paint a Frog’
                            theme should submit a scanned version of the
                            painting in PDF format. The PDF should be no larger
                            than 2MB.
                          </li> -->
                          <li>
                            All submitted photos must contain the original EXIF
                            metadata information. There must be no border(s),
                            logo(s), copyright marks, identifying marks or any
                            other visible references and/or marks on the image.
                          </li>
                          <li>
                            The contestants are allowed to submit ONLY ONE entry
                            per individual.
                          </li>
                          <li>
                            Basic editing, including cropping and colour
                            corrections are allowed. Any other editing and
                            manipulations are not acceptable.
                          </li>
                          <li>
                            WTI shall reserve the right to call for original
                            JPEG or RAW files with unchanged EXIF for the
                            purpose of authentication. An image may be
                            disregarded if this information cannot be provided.
                          </li>
                          <!-- <li>
                            Participants submitting entries for ‘Be a Frog’
                            theme are NOT ALLOWED to use stickers or stencils.
                          </li> -->
                          <li>
                            Participants to ensure no animals/humans are hurt,
                            disturbed or harmed in any other way for the
                            contest.
                          </li>
                          <br />
                          <b>
                            <span style="font-size:22px !important;">
                              The deadline for submission of the entries has
                              been extended till 22 June '22. The results and
                              winners will be anounced on 25 June '22. All rules
                              and guidelines for the contest remain the same.
                            </span>
                          </b>
                        </ul>
                      </span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span style="font-size:18px" class="poppinssemibold">
                        JUDGING
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="poppinsregular" style="font-size:18px">
                        <ul class="a">
                          <!-- <li>
                            WTI will appoint a separate jury panel for all three
                            themes ‘Click a Frog’, ‘Paint a Frog’, ‘Be a Frog’.
                          </li> -->
                          <li>
                            WTI will appoint a separate jury panel for ‘Click a
                            Frog’.
                          </li>
                          <li>
                            The jury panels will assess and determine the
                            winning entries for the theme. The results and the
                            winners will be announced on WTI website contest
                            page.
                          </li>
                          <li>
                            The decision of WTI’s jury panels will be final and
                            binding on all Participants in respect to all
                            matters relating to the Contest.
                          </li>
                        </ul>
                      </span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span style="font-size:18px" class="poppinssemibold">
                        PRIZES
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="poppinsregular" style="font-size:18px">
                        <ul class="a">
                          <li>Prizes will be shipped for free within India.</li>
                          <li>
                            Any state/ provincial/ territorial, and/or local
                            taxes, fees and surcharges and taxes on the prize
                            that may be awarded to the winners under the Contest
                            will be solely paid by the winners.
                          </li>
                        </ul>
                      </span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span style="font-size:18px" class="poppinssemibold">
                        INTELLECTUAL PROPERTY RIGHTS
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="poppinsregular" style="font-size:18px">
                        <ul class="a">
                          <li>
                            Submitted photos must be original, created and/or
                            taken by the contestant. It must not contain any
                            materials owned or controlled by a third party for
                            which you have not obtained a license, must not
                            infringe the copyright, trademark, moral rights,
                            rights of privacy/publicity or intellectual property
                            rights of any person or entity.
                          </li>
                          <li>
                            WTI respects photographers’ copyrights and
                            copyrights shall remain vested with the creator of
                            the image/artwork. Upon making the submission, you
                            grant WTI, worldwide, non-exclusive, royalty free,
                            sub-licensable right and license to use, publish,
                            reproduce, display, perform, adapt, create
                            derivative works, distribute, have distributed,
                            print, in whole or in part, in any form, in all
                            media forms now or hereafter known, for
                            organisational and promotional purposes.
                          </li>
                          <li>
                            The artist will be credited wherever the
                            image/artwork is used.
                          </li>
                        </ul>
                      </span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span style="font-size:18px" class="poppinssemibold">
                        LIABILITY
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="poppinsregular" style="font-size:18px">
                        <ul class="a">
                          <li>
                            You hereby hold harmless, release, indemnify and
                            discharge WTI and its partners, affiliates,
                            subsidiaries, advertising agencies, agents and their
                            employees, officers and representatives from all
                            liability, claims, judgments, demands,
                            controversies, agreements, damages, actions and
                            causes of action whatsoever, arising out of or
                            related in any way to the contest or the conduct of
                            the contest and the acceptance and use, misuse, or
                            possession of any prize awarded, whether in law or
                            in equity, no matter what the cause or nature. You
                            further waive any claims that You may state or
                            assert against WTI in association with the contest
                            or any of its associated activities, or in any way
                            related to or resulting from the contest even if
                            such injury or claims results from or is caused by
                            the negligence or gross negligence of WTI, in whole
                            or in part, due to human error or otherwise. You
                            further agree to indemnify and hold harmless WTI
                            from any claim arising out of your participation in
                            the contest including, without limitation, all
                            claims brought or asserted by any third party as a
                            result of any injury or loss that You or they may
                            sustain in any way associated with your
                            participation in the contest.
                          </li>
                          <li>
                            Under no circumstance will WTI be liable for any:
                            <ul class="b">
                              <li>
                                lost, late, misdirected, stolen, illegible or
                                incomplete Submissions
                              </li>
                              <li>
                                error, omission, interruption, deletion, defect,
                                delay in operation or transmission,
                                communications line failure, theft or
                                destruction or unauthorized access to, or
                                alteration of, submitted Photos/artwork;
                              </li>
                              <li>
                                problems, failures or technical malfunction of
                                any telephone network or lines, computer online
                                systems, servers, providers, computer equipment,
                                software, email, players or browsers, on account
                                of technical problems or traffic congestion on
                                the Internet, at any website, or on account of
                                any combination of the foregoing;
                              </li>
                              <li>
                                incorrect or inaccurate information, caused by
                                any of the equipment or programming associated
                                with or utilized in the Contest or by any
                                technical or human error which may occur in the
                                processing of the Photos and/or the Submissions;
                                and/or
                              </li>
                              <li>
                                injury or damage to any Participant or to any
                                computer related to, resulting from or in
                                connection with the Contest.
                              </li>
                            </ul>
                          </li>
                          <li>
                            If, for any reason, the contest is not capable of
                            being conducted as anticipated, due to computer
                            virus, bugs, tampering, unauthorized intervention,
                            fraud, technical failures, or any other causes
                            beyond the control of WTI, which corrupt or affect
                            the administration, security, fairness, integrity or
                            proper conduct of the contest, WTI reserves the
                            right at its sole discretion to cancel, terminate,
                            modify or suspend the contest as deemed appropriate,
                            disqualify any Participant, and/or select winners
                            from all eligible Photos submitted prior to the
                            termination, cancellation, modification or
                            suspension. WTI reserves the right to correct any
                            typo-graphical, printing, computer programming or
                            operating errors at any time.
                          </li>
                          <li>
                            <b> GOVERNING LAW </b>
                            <br />
                            The Contest, Your Submission(s) and the Rules shall
                            be governed by and construed in accordance with
                            Indian laws. Any aspects or disputes arising out of
                            or in connection with the contest and/or your
                            submission(s) will be subject to the exclusive
                            jurisdiction of the India courts.
                          </li>
                        </ul>
                      </span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Quiz from "./Quiz";
export default {
  props: ["banner", "doScroll"],
  components: {
    Quiz,
  },
  watch: {
    doScroll() {
      if (this.doScroll) {
        this.scroll("ruleId");
      }
    },
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      rulesInfo: [],
      ruleselected: null,
      cardData: [
        {
          title: "Click a Frog",
          image: require("./../../assets/images/CLICK.jpg"),
          // image: require("./../../assets/images/f1.jpg"),
          desc:
            "For all the photographers out there, click a frog in its natural habitat, and share the picture with us. Top three entries selected by our jury will win exciting prizes!",
        },
        // {
        //   title: "Paint a Frog",
        //   image: require("./../../assets/images/DRAW.jpg"),
        //   // image: require("./../../assets/images/f2.jpg"),
        //   desc:
        //     "Love to paint, but haven’t picked up your brush for a while? Why not paint a frog and share the painting. Our jury will select the top three artworks for the awesome prizes!",
        // },
        // {
        //   title: "Be a Frog",
        //   image: require("./../../assets/images/PAINT.jpg"),
        //   // image: require("./../../assets/images/f3.jpg"),
        //   desc:
        //     "Dare to be a frog? Well, the theme is to paint your face as a frog. The three most creative, whacky, original submissions are up for the lovely prizes! Surprise us!",
        // },
      ],

      socialMedias: [
        {
          network: "whatsapp",
          title: "Whatsapp",
          icon: "mdi-whatsapp",
          image: "whatsapp.png",
          size: "50px",
        },
        // { network: "Messenger", icon: "mdi-facebook-messenger" },
        {
          network: "facebook",
          title: " Facebook",
          icon: "mdi-facebook",
          image: "fb.png",
          size: "50px",
        },
        {
          network: "twitter",
          title: " Twitter",
          icon: "mdi-twitter",
          image: "twitter.png",
          size: "50px",
        },
        {
          network: "email",
          title: "Gmail ",
          icon: "mdi-email",
          image: "gmail.png",
          size: "58px",
        },
        {
          network: "linkedin",
          title: "Linkedin ",
          icon: "mdi-linkedin",
          image: "linkedin.png",
          size: "50px",
        },
        // { network: "telegram",title:"Telegram ",  icon: "mdi-telegram" ,size:"50px"},
      ],
      shareDialog: false,
      isClickedPrize: false,
    };
  },
  methods: {
    scroll(id) {
      id = "ruleId";
      console.log("Scrlled-=", id);
      document.getElementById("ruleId").scrollIntoView({
        behavior: "smooth",
      });
      this.doScroll = false;
    },

    // scroll(id) {
    //   console.log("Scrlled-=",id)
    //   document.getElementById(id).scrollIntoView({
    //     behavior: "smooth",
    //   });
    //   this.doScroll = false;
    // },

    getImgUrl(pic) {
      return require("../../assets/images/social/" + pic);
    },
    clickedOn() {
      // this.$emit("bstepper", {
      //   isClicked: true,
      // });

      document.getElementById('subform').scrollIntoView({
        behavior: "smooth",
      }); 
    },

    clickedOn2() {
      // this.$emit("stepperPrize", {
      //   isClickedPrize: true,
      // });

      document.getElementById('viewWin').scrollIntoView({
        behavior: "smooth",
      }); 
    },


     
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
.bannerGrad {
  background: transparent;

  background-image: linear-gradient(
    104deg,
    #bc4639 6%,
    #7e7e7e2a 94%,
    transparent 100%
  );
}
.rot {
  animation-duration: 3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
.texth {
  line-height: 1.5em;
  min-height: 9.5em;
  float: left;
  width: 100%;
}
.hovCls {
  color: #005f32;
  border-bottom: 3px solid #005f32;
}

.hovCls:hover {
  color: blue;
  border-bottom: 3px solid blue;
}
</style>
