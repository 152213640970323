<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 sm11 py-6>
        <v-layout wrap fill-height>
          <!-- <v-flex xs12 align-self-center pa-4>
            <v-card :rounded="true" tile>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-layout wrap py-8>
                  <v-flex
                    xs12
                    px-4
                    px-sm-8
                    py-2
                    pb-10
                    align-self-center
                    text-left
                  >
                    <span
                      style="font-size:23px;color:#000;"
                      class=" poppinsbold text-uppercase"
                      ><b> Submission Form</b></span
                    >
                  </v-flex>
                  <v-flex xs12 px-4 px-sm-8 pb-5>
                    <v-layout wrap>
                      <v-flex xs12 sm6 text-left pa-4>
                        <v-text-field
                          class="tes"
                          label="First Name"
                          v-model="contact.firstName"
                          :rules="reqRules"
                          color="#005f32"
                          required
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 text-left pa-4>
                        <v-text-field
                          class="tes"
                          label="Last Name"
                          v-model="contact.lastName"
                          :rules="reqRules"
                          color="#005f32"
                          required
                          hide-details
                          dense
                          outlined
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 text-left pa-4>
                        <v-text-field
                          required
                          :rules="emailRules"
                          class="tes"
                          label="Email"
                          v-model="contact.email"
                          color="#005f32"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 text-left pa-4>
                        <v-select
                          class="tes"
                          label="Select Theme"
                          required
                          :rules="reqRules"
                          v-model="contact.selectTheme"
                          :items="themeList"
                          item-text="name"
                          item-value="name"
                          dense
                          outlined
                          hide-details
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 text-left pa-4>
                        <v-text-field
                          class="tes"
                          label="Description"
                          v-model="contact.description"
                          :rules="reqRules"
                          color="#005f32"
                          required
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 text-left pa-4>
                        <v-checkbox
                          v-model="contact.issolemnly"
                          hide-details
                          class="tes"
                        >
                          <template v-slot:label>
                            <div>
                              I solemnly abide to the
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <span
                                    style="border-bottom:1px solid #000;"
                                    @click="clickedOn()"
                                    v-on="on"
                                  >
                                    contest rules
                                  </span>
                                </template>
                                Click to view contest rules
                              </v-tooltip>
                              stated.
                            </div>
                          </template>
                        </v-checkbox>
                      </v-flex>
                      <v-flex xs12 text-left pa-4>
                        <v-checkbox
                          v-model="contact.ismonthly"
                          class="tes"
                          hide-details
                          label="Send me monthly newsletter on WTI projects."
                        ></v-checkbox>
                      </v-flex>

                      <v-flex xs12 text-left pa-4 py-4>
                        <span
                          style="
                                color: #363434 !important;
                                font-family: poppinssemibold;
                              "
                          >Upload Image</span
                        >
                        &nbsp;
                        <input
                          v-show="false"
                          accept="image/*,.pdf"
                          id="file1"
                          ref="files"
                          type="file"
                          @change="browseImage"
                        />
                        <v-btn outlined class="rounded-0" color="error">
                          <v-icon @click="$refs.files.click()" color="error"
                            >mdi-upload</v-icon
                          >
                        </v-btn>
                        <br />
                        <br />
                        <span
                          style="
                                padding-bottom: 6px;
                                border-bottom: 2px solid grey;
                              "
                          v-if="cvFile"
                          >{{ cvFile.name }}
                          <v-icon color="red" small @click="cvFile = null"
                            >mdi-close</v-icon
                          ></span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs12 align-self-center>
                    <v-spacer></v-spacer>
                  </v-flex>

                  <v-flex px-8 align-self-end text-center>
                    <v-btn
                      @click="validate"
                      :disabled="!valid"
                      dark
                      x-large
                      rounded
                      color="#005f32"
                      :ripple="false"
                    >
                      <span>Submit</span>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card>
          </v-flex> -->

          <v-flex
          id="viewWin"
            xs12
            align-self-center
            pa-4
            py-12
            style="border-bottom:3px solid #005f32"
          >
            <v-layout wrap fill-height justify-center>
              <v-flex xs12 sm4 lg3  align-self-center text-center >
                <v-btn
                  block
                  :to="'/Winners'"
                  color="#005f32"
                  dark
                  x-large
                  class="rounded-lg text-none"
                >
                  <span class="poppinssemibold" style="color:#ffffff">
                    View the Winners!
                  </span>
                </v-btn>
              </v-flex>
              <v-flex xs12 pa-12>
                <v-spacer></v-spacer>
              </v-flex>
              <v-flex xs12 md6 align-self-center pa-1>
                <v-card tile flat class="pa-2">
                  <span
                    style="font-size:18px;color:#000;"
                    class=" poppinssemibold text-none"
                  >
                    Hey there! The contest ended on 22nd
                    of June.
                  </span>
                  <br />
                  <br />
                  <span
                    style="font-size:18px;color:#000;"
                    class=" poppinsregular text-none"
                  >
                    <span
                      class="poppinssemibold"
                      style="cursor:pointer;color:#005f32"
                      >Sign Up</span
                    >
                    for our newsletter to stay up to date about more such
                    exciting contests and giveways and to learn about WTI's
                    wildlife conservation initiatives.
                  </span>
                </v-card>
              </v-flex>
              <v-flex xs12 md6 align-self-center pa-1>
                <v-card tile class="pa-2 rounded-lg" color="#005f32">
                  <v-form ref="sform" v-model="svalid" lazy-validation>
                    <v-layout wrap pa-4>
                      <v-flex xs12 align-self-center px-2 pb-2>
                        <span
                          class="poppinsregular text-uppercase"
                          style="font-size:22px;color:#ffffff"
                        >
                          Sign Up for our newsletter
                        </span>
                      </v-flex>
                      <v-flex xs12 sm6 align-self-center pa-2>
                        <v-text-field
                          class="vtexty"
                          color="#ffffff"
                          :rules="reqRules"
                          hide-details
                          v-model="sfirstName"
                          label="First name"
                          dense
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 align-self-center pa-2>
                        <v-text-field
                          class="vtexty"
                          color="#ffffff"
                          :rules="reqRules"
                          v-model="slastName"
                          hide-details
                          label="Last name"
                          dense
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm8 align-self-center pa-2>
                        <v-text-field
                          class="vtexty"
                          color="#ffffff"
                          v-model="semail"
                          :rules="emailRules"
                          hide-details
                          label="Email"
                          dense
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm4 align-self-center pa-2 text-right>
                        <v-btn
                          dark
                          large
                          block
                          class="rounded-lg"
                          color="#ffffff"
                          v-if="svalid"
                          @click="subscribeNow()"
                        >
                          <span class="poppinssemibold" style="color:#005f32"
                            >Subscribe</span
                          >
                        </v-btn>
                        <v-btn
                          dark
                          large
                          block
                          class="rounded-lg"
                          color="#ffffff"
                          v-else
                          @click="sValidate()"
                        >
                          <span class="poppinssemibold" style="color:#005f32"
                            >Subscribe</span
                          >
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 align-self-center px-4 pt-10 pb-16>
            <v-layout wrap id="prizeCol">
              <v-flex xs12 align-self-center text-center pa-2>
                <span
                  style="font-size:23px;color:#000;"
                  class=" poppinsbold text-none"
                >
                  Did we mention that there will be Prizes?
                </span>
              </v-flex>
              <v-flex xs12 align-self-center text-center pa-2>
                <span
                  style="font-size:19px;color:#000;"
                  class=" poppinssemibold text-none"
                >
                  The Grand Prize Winner for the photo contest ‘Click a Frog’
                  will:
                </span>
              </v-flex>

              <v-flex xs12 align-self-center text-center pa-2>
                <span
                  style="font-size:24px;color:#fa7e0a;"
                  class=" poppinsbold text-none"
                >
                  Win INR 5,000
                </span>
              </v-flex>
              <v-flex xs12 align-self-center text-center pa-2>
                <span
                  style="font-size:17px;color:#000;"
                  class=" poppinsregular text-none"
                >
                  Set of three frog-printed masks
                  <br />
                  One ‘Animal Lover’ car sticker
                  <br />

                  A set of 3 magnetic coasters
                  <br />

                  A WTI T-shirt
                </span>
              </v-flex>

              <v-flex xs12 align-self-center text-center pa-2>
                <span
                  style="font-size:19px;color:#000;"
                  class=" poppinssemibold text-none"
                >
                  The 2nd and 3rd place winners will get all of the above but
                  NOT the cash prize.
                </span>
              </v-flex>
              <v-flex xs12 align-self-center text-center pa-2>
                <span
                  style="font-size:19px;color:#000;"
                  class=" poppinssemibold text-none"
                >
                  The 3 Quiz winners chosen at random will get all of the above
                  but NOT the cash prize.
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 align-self-center px-4 pt-10 pb-16>
            <span
              :style="
                $vuetify.breakpoint.name == 'xs'
                  ? 'color:#000;font-size:18px'
                  : 'color:#000;font-size:22px'
              "
              class="poppinssemibold"
            >
              For queries, please write to
              <a target="_blank" href="mailto:po.communication@wti.org.in">
                <span style="color:blue">po.communication@wti.org.in</span>
              </a>
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["idd", "notThis"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      rulesInfo: [],
      ruleselected: null,
      reqRules: [(v) => !!v || "This field is required*"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      valid: true,
      svalid: true,
      themeList: [
        {
          name: "Click a frog",
        },
        // {
        //   name: "Paint a frog",
        // },
        // {
        //   name: "Be a frog",
        // },
      ],
      contact: {
        firstName: "",
        lastName: "",
        issolemnly: true,
        ismonthly: true,
        selectTheme: null,
        description: "",
        email: "",
      },
      formDataCv: new FormData(),
      cvFile: null,
      semail: "",
      sfirstName: "",
      slastName: "",
    };
  },
  beforeMount() {
    this.contact.selectTheme = this.themeList[0];
  },
  watch: {
    idd() {
      if (this.idd && this.notThis == 10) {
        this.scroller(this.idd);
      }
    },
  },
  methods: {
    sValidate() {
      if (!this.sfirstName) {
        this.msg = "Firstname is required*";
        this.showSnackBar = true;
        return;
      } else if (!this.slastName) {
        this.msg = "Lastname is required*";
        this.showSnackBar = true;
        return;
      } else if (!this.semail) {
        this.msg = "Email is required*";
        this.showSnackBar = true;
        return;
      }
    },
    subscribeNow() {
      this.sfirstName = this.sfirstName.trim();
      this.slastName = this.slastName.trim();
      this.semail = this.semail.trim();
      this.appLoading = false;
      if (!this.sfirstName) {
        this.msg = "Firstname is required*";
        this.showSnackBar = true;
        return;
      } else if (!this.slastName) {
        this.msg = "Lastname is required*";
        this.showSnackBar = true;
        return;
      } else if (!this.semail) {
        this.msg = "Email is required*";
        this.showSnackBar = true;
        return;
      } else {
        var data = {};
        data["firstName"] = this.sfirstName;
        data["lastName"] = this.slastName;
        data["email"] = this.semail;
        axios({
          method: "POST",
          url: "/newsletter/add",
          data: data,
        })
          .then((response) => {
            if (response.data.status) {
              this.appLoading = false;
              // this.msg="Thank you!"
              // this.showSnackBar=true
              this.$refs.sform.resetValidation();
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
            this.clearOff();
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;

            console.log(err);
          });
      }
    },
    scroller(id) {
      id = "prizeCol";
      console.log("priz id called=", id);
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
      this.idd = null;
    },
    clickedOn() {
      // this.$emit("stepper", {
      //   isClicked: true,
      // });
      document.getElementById("ruleId").scrollIntoView({
        behavior: "smooth",
      });
    },
    browseImage(event) {
      var got = event.target.files[0];

      if (got.type == "application/pdf") {
        this.cvFile = got;
      } else {
        var img;
        img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
        var ty = event.target.files[0];
        console.log(ty);
        if (ty.size > 3145728) {
          this.msg = "File size Should be less than 3MB";
          this.showSnackBar = true;
          return;
        } else {
          this.cvFile = got;

          // img.onload = () => {
          //   this.cvFile = URL.createObjectURL(event.target.files[0]);
          // };
          // console.log(img);
        }
      }
    },
    validate() {
      if (
        this.$refs.form.validate() &&
        this.contact.issolemnly &&
        this.cvFile
      ) {
        this.addApplication();
        // else {
        //   this.msg = "Please upload CV";
        //   this.showSnackBar = true;
        // }
      } else if (!this.cvFile) {
        this.msg = "Please upload image!";
        this.showSnackBar = true;
      } else {
        this.msg = "Please check all options!";
        this.showSnackBar = true;
      }
    },

    addApplication() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/quiz/formdata/add",
        data: this.contact,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;

            if (response.data.data._id) {
              if (this.cvFile) {
                this.uploadCV(response.data.data._id);
              }
            }
            this.clearOff();
            // Object.keys(this.contact).forEach(
            //   (key) => (this.contact[key] = '')
            // );
            this.flag = true;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;

          console.log(err);
        });
    },

    uploadCV(item) {
      this.appLoading = true;
      this.formDataCv.append("id", item);
      this.formDataCv.append("photo", this.cvFile);
      axios({
        method: "POST",
        url: "/quiz/upload/photo",
        data: this.formDataCv,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Uploaded Successully";
            this.showSnackBar = true;
            this.formDataCv = new FormData();
            this.cvFile = null;
            this.$refs.form.resetValidation();
            // this.$router.go();
          } else {
            this.formDataCv = new FormData();
            this.msg = "Can't Upload CV.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Uncaught!Can't Upload CV.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    clearOff() {
      this.contact.firstName = "";
      this.contact.lastName = "";

      this.contact.issolemnly = true;
      this.contact.ismonthly = true;
      this.contact.selectTheme = null;
      this.contact.description = "";
      this.contact.email = "";
      this.slastName = "";
      this.sfirstName = "";
      this.semail = "";
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
.bannerGrad {
  background: transparent;

  background-image: linear-gradient(
    104deg,
    #bc4639 6%,
    #7e7e7e2a 94%,
    transparent 100%
  );
}
.rot {
  animation-duration: 3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
</style>
